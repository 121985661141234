import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import logo from '../assets/logo/logo-white-transparent.svg'

const Header = props => (
  <header id="header" className="alt">
    <Link to="/" className="logo" alt="Home" aria-label="HOVR Logo, home link">
      <span className="image fit">
        <img alt="HOVR logo" src={logo} />
      </span>
      {/* <strong>HOVR</strong> <span>California</span> */}
    </Link>
    <nav>
      <button className="menu-link" onClick={props.onToggleMenu} href="">
        Menu
      </button>
    </nav>
  </header>
)

Header.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Header
