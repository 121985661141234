import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Banner from '../components/Banner'

class Generic extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <html lang="en" />
          <title>Environmental Impact</title>
          <meta name="description" content="HOVR Environmental impact page" />
        </Helmet>
        <Banner
          bannerImage={this.props.data.bannerImage}
          bannerSubtitle="Protecting the Bay, while enabling access"
          bannerTitle="Conservation"
        />

        <div id="main">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h2>
                  Net Zero CO<sub>2</sub> Emissions
                </h2>
              </header>
              <p>
                Hovercraft operated by HOVR use modern efficient diesel engines,
                fueled with renewable diesel. By utilizing renewable diesel,
                HOVR emits 50% less carbon compared to traditional diesel fuel.
                HOVR is committed to fully offsetting 100% of its CO
                <sub>2</sub> emissions, company-wide. What we can't reduce, we
                offset with carbon credits from{' '}
                <a href="https://terrapass.com">Terrapass</a>.
                <br />
                In addition to offsetting our own carbon emissions, each
                hovercraft takes cars off the road, further reducing emissions.
              </p>
            </div>
          </section>
          <div className="inner">
            <header className="major">
              <h2>Ecology</h2>
            </header>
            <p>
              Hovercraft are better for the environment compared to conventional
              ferries as they create:
            </p>
            <ul>
              <li>No shoreline erosion</li>
              <li>Minimal wildlife disturbance</li>
              <li>Zero water pollution</li>
            </ul>
            <p>HOVR goes further to protect the Bay by ensuring:</p>
            <ul>
              <li>
                All HOVR captains receive training on wildlife avoidance and
                environmental stewardship.
              </li>
              <li>
                Our permanent structures are designed to be energy-neutral with
                green roofs, water recycling, and additional impact-minimization
                technologies.
              </li>
              <li>
                Hoverpads and ramps are constructed from permeable materials to
                minimize runoff.
              </li>
              <li>
                Water and space heating are provided by passive solar thermal
                systems.
              </li>
            </ul>
          </div>
          <div className="inner">
            <header className="major">
              <h2>Addressing Noise</h2>
            </header>
            <p>
              Our hovercraft are quieter than the 88 dBA that a commuter is
              exposed to on BART. Modern hovercraft are significantly quieter
              than the previous generation. The reduction in noise comes from
              investments in quieter, more efficient engines, and longer, slower
              moving propellers.
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Generic

export const pageQuery = graphql`
  query {
    bannerImage: file(
      relativePath: { eq: "landry-gapangwa-737553-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
