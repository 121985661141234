import React from 'react'

class Banner extends React.Component {
  render() {
    // Setup inline style objects using ES6 template literals which pull in the correct paths from the content pages themselves
    const bannerImage = {
      backgroundImage: `url(${
        this.props.bannerImage.childImageSharp.fluid.src
      })`,
    }

    return (
      <section id="banner" className="major" style={bannerImage}>
        <div className="inner">
          <header className="major">
            <h1>{this.props.bannerTitle}</h1>
          </header>
          <div className="content">
            <p>{this.props.bannerSubtitle}</p>
          </div>
        </div>
      </section>
    )
  }
}
export default Banner
